<script>
import app from '../../store/app.js';
import StatePostNotificationSettings from '../Post/Notification/Settings.vue';

export default {
	name: "settings-notifications",
	components: {StatePostNotificationSettings},
	data() {
		return {
            app
        }
    }
}
</script>

<template>
	<phi-page :loading="app.api.isLoading" class="state-phi-settings-notifications">
		<div slot="toolbar">
			<mu-icon-button @click="$router.go(-1)" icon="arrow_back"></mu-icon-button>
			<h1>{{ $t('noun.notifications') }}</h1>
		</div>

        <state-post-notification-settings :personId="app.user.id"></state-post-notification-settings>
	</phi-page>
</template>

<style lang="scss">
.state-phi-settings-notifications {
	section {
		margin-bottom: 2em;

		h2 {
			font-weight: 1em;
			text-transform: uppercase;
			color: #666;
			margin-bottom: 0.5em;
			font-size: 0.8em;
		}
	}
}
</style>