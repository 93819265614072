<script>
import PhiSchedulePicker from '../../Schedule/Picker.vue';

export default {
    name: "phi-post-notification-destination",

    components: {PhiSchedulePicker},

    props: {
        value: {
            type: Object,
            required: true
        },

        isGeneral: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            isOpen: false
        };
    }
}
</script>

<template>
    <div class="phi-post-notification-destination" :class="{disabled: value.isEnabled != 1}">
        <div class="phi-media head" :class="{open: isOpen}">

            <mu-icon
                class="toggler phi-media-figure"
                @click="isOpen = !isOpen" :value="isOpen ? 'keyboard_arrow_down' : 'keyboard_arrow_up'"
            ></mu-icon>

            <div class="phi-media-body" @click="isOpen = !isOpen">
                <div v-if="isGeneral">
                    <h1 v-text="$t('transport.' + value.transport)"></h1>
                </div>
                <div v-else>
                    <h1 v-text="value.destination ? value.destination : $t('noun.defaultValue')"></h1>
                </div>

                <phi-schedule-picker
                    class="schedule inline"
                    v-model="value.schedule"
                    :disabled="value.isEnabled != 1"
                    @change="$emit('change')"
                ></phi-schedule-picker>

            </div>

            <phi-schedule-picker
                class="schedule phi-media-right"
                v-model="value.schedule"
                :disabled="value.isEnabled != 1"
                @change="$emit('change')"
            ></phi-schedule-picker>


            <mu-switch
                class="switch phi-media-right"
                :value="value.isEnabled == 1"
                @change="value.isEnabled = value.isEnabled == 1 ? 0 : 1; $emit('change')"
            ></mu-switch>

            <mu-checkbox
                v-if="isGeneral"
                class="isEditable phi-media-right"
                :value="!value.isEditable"
                @change="value.isEditable = !value.isEditable; $emit('change')"
                uncheckIcon="lock_open"
                checkedIcon="lock"
            ></mu-checkbox>

            <!-- <mu-icon
                class="toggler phi-media-right"
                @click="isOpen = !isOpen" :value="isOpen ? 'keyboard_arrow_down' : 'keyboard_arrow_up'"
            ></mu-icon> -->
        </div>

        <div class="destination-preferences">
            <phi-drawer :open="isOpen">

                <div class="destination-options phi-media" v-if="!isGeneral && !!value.destination">
                    <div class="phi-media-body"></div>
                    <mu-icon
                        value="clear"
                        class="delete phi-media-right"
                        @click="$emit('delete')"
                    ></mu-icon>
                </div>

                <div class="container">
                    <template v-for="preference in value.preferences">
                        <div v-if="isGeneral || preference.isEditable != false" class="phi-media preference">
                            <div class="phi-media-body">
                                <h1 v-text="$t(preference.type)"></h1>

                                <phi-schedule-picker
                                    class="schedule inline"
                                    v-model="preference.schedule"
                                    :disabled="preference.isEnabled != 1"
                                    @change="$emit('change')"
                                ></phi-schedule-picker>
                            </div>

                            <phi-schedule-picker
                                class="schedule phi-media-right"
                                v-model="preference.schedule"
                                :disabled="preference.isEnabled != 1"
                                @change="$emit('change')"
                            ></phi-schedule-picker>

                            <mu-switch
                                class="switch phi-media-right"
                                :value="preference.isEnabled == 1"
                                @change="preference.isEnabled = preference.isEnabled == 1 ? 0 : 1; $emit('change')"
                            ></mu-switch>

                            <mu-checkbox
                                v-if="isGeneral"
                                class="isEditable phi-media-right"
                                :value="!preference.isEditable"
                                @change="preference.isEditable = !preference.isEditable; $emit('change')"
                                uncheckIcon="lock_open"
                                checkedIcon="lock"
                            ></mu-checkbox>
                        </div>
                    </template>
                </div>
            </phi-drawer>
        </div>

    </div>
</template>

<style lang="scss">
.mu-switch {
  input[type="checkbox"] {
    &:checked {
      + .mu-switch-wrapper {
        .mu-switch-track{
          background-color: #80c6c3;
        }
        .mu-switch-thumb{
          background-color: #009680;
          color: #009680;
        }
      }

    }
  }
}
.phi-post-notification-destination {

    .schedule.inline {
        display: none;
    }

    .schedule.phi-media-right {
        display: block;
    }

    @media (max-width: 360px) {
        .schedule.inline {
            display: block;
        }

        .schedule.phi-media-right {
            display: none;
        }
    }


    .head {
        cursor: pointer;
        align-items: center;
        padding: 10px 12px;

        h1 {
            font-size: 1em;
        }

        .phi-media-body {
            overflow: hidden;
        }

        .phi-media-figure {
            width: 24px;
        }

        .toggler {
            opacity: .5;
        }

        &.open {
            background-color: rgba(0, 0, 0, .03);
        }
    }


    .destination-preferences {
        .container {
            padding-bottom: 32px;
        }
    }

    .destination-options {
        background-color: rgba(0, 0, 0, .03);
        font-size: .8em;
        padding: 12px;

        align-items: center;

        .delete {
            color: #900;
            opacity: .8;
        }
    }

    .preference {
        h1 {
            font-size: 1em;
        }
    }

    .head,
    .preference {
        h1 {
            font-weight: normal;
        }

        p {
            font-size: .8em;
            opacity: .7;
        }

        .phi-media-right {
            margin-left: 16px;
        }
    }

    &.disabled .destination-preferences {
        pointer-events: none;
        opacity: .5;
    }
}
</style>